import { graphql } from "gatsby"
import { Link, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout.js"
import Seo from "../components/seo.js"
import Testimonials2 from "../components/swiper/testimonial-swiper.js"
//components
import PartnerSwiper from "../components/swiper/swiper-partner.js"

import Modal from "../components/modal/quickbase-data-modal.js"

import { GrVulnerability, GrShieldSecurity, GrCompliance } from "react-icons/gr"
import {
  AiOutlineSecurityScan,
  AiFillAlert,
  AiOutlineSafetyCertificate,
} from "react-icons/ai"
import { TbLockAccess, TbReportAnalytics } from "react-icons/tb"
import { MdOutlineSecurity, MdOutlineLockClock } from "react-icons/md"
import { BiCertification } from "react-icons/bi"
import { GoShieldLock } from "react-icons/go"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero/hero-quickbase-security-audit.js"
import QuickbaseSections from "../components/quick-base-solutions/QuickbaseSections.js"

import security from "../images/new-images/security-audit.jpg"
import about1 from "../images/new-images/about1.jpg"
import training from "../images/new-images/training-support.jpg"
import datasec from "../images/new-images/datasec.jpg"
import support from "../images/new-images/support.jpg"

class QuickbaseSecurityAuditPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo
          title="Application Security Audit | Quandary Consulting Group"
          description="From small issues to potentially catastrophic failures, we help you find and prioritize your security vulnerabilities with our Quickbase security audit."
        />
        <div id="main">
          <LazyLoadComponent>
            <section id="logos-wrapper" className="">
              <LazyLoadComponent>
                <PartnerSwiper />
              </LazyLoadComponent>
            </section>

            <LazyLoadComponent>
              <section
                id="it-consulting"
                className="it-consulting bg-white"
                style={{ padding: "0rem 0" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-8 mx-auto text-center">
                      <h2 className="mb-2 mb-2 font-weight-medium">
                        Identify Risks. Prioritize Improvements. <br /> Drive
                        Business Value.
                      </h2>
                      <p className="mt-4">
                        We analyze your Quickbase applications from every angle.
                        Then build an agile roadmap for you,
                        <br /> focusing on the highest priority items that drive
                        the most value.
                      </p>
                      <div className="hero-button">
                        <Link
                          to="/contact/"
                          className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                          style={{ marginTop: "auto" }}
                        >
                          Book Your Security Consultation
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                      marginTop: "3rem",
                    }}
                  >
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                      <h3>Stay Compliant and Secure</h3>
                      <p className="mt-4">
                        No business wants to be at the center of a data breach.
                        We evaluate your Quickbase application for potential
                        compliance and data security concerns using our 10-point
                        security audit. After, your team receives a
                        comprehensive report on your app security along with a
                        roadmap for implementing fixes that you can share with
                        your team or work with us to fix.
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/security-audit.jpg"
                        alt="Security Audit"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img
                        src={security}
                        className="img-fluid mobile-appear-img"
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/about1.jpg"
                        alt="Training and Support"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img
                        src={about1}
                        className="img-fluid mobile-appear-img"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                      <h3>Training and Support</h3>
                      <p className="mt-4">
                        Protect your investment by keeping future applications
                        secure. We can train your team on how to properly review
                        activities, vet employees, and build systems for
                        continued compliance and application security.
                      </p>
                    </div>
                  </div>
                  <div
                    className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                  >
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                      <h3>Custom, Hands-On Testing</h3>
                      <p className="mt-4">
                        Our team manually conducts the security audit using our
                        extensive knowledge of Quickbase to find weaknesses in
                        your current applications. Get peace of mind with a
                        tailored strategy for improving your application
                        security designed by our team directly.
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/training-support.jpg"
                        alt="Custom, Hands-On Testing"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img
                        src={training}
                        className="img-fluid mobile-appear-img"
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/datasec.jpg"
                        alt="Access Control Done Right"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img
                        src={datasec}
                        className="img-fluid mobile-appear-img"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                      <h3>Access Control Done Right </h3>
                      <p className="mt-4">
                        Without proper access controls in place, users can still
                        view restricted data. Whether you rely on form rules and
                        reports to display data without control access in place
                        or you have not set up access controls, your data is not
                        secure. We identify issues with your access controls to
                        ensure only the right people can access your critical
                        data. No exceptions.
                      </p>
                    </div>
                  </div>
                  <div
                    className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                  >
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                      <h3>Implementation Support</h3>
                      <p className="mt-4">
                        After the audit, we can work with your team to implement
                        changes to improve your application security within your
                        current project roadmap. Using two-week sprints, we
                        focus on the most critical, high-value tasks. That way,
                        you get the most value upfront. Get the most out of your
                        Quickbase applications without sacrificing your goals or
                        putting your business at unnecessary risk.
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/support.jpg"
                        alt="Advanced Technical Design Documentation"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img
                        src={support}
                        className="img-fluid mobile-appear-img"
                      />
                    </div>
                    {/* </div>
                 <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/tech-debt.jpg"
                    alt="Tech Debt Assessment and Clean-Up"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Tech Debt Assessment and Clean-Up</h3>
                  <p className="mt-4">
                    We’ll find Quick-Fix Tech Debt and offer remediation
                    suggestions. For example: Removing deprecated or unconnected
                    tables, fields, reports, dashboards, homepages, forms, and
                    roles
                  </p>
                  <p className="mt-4">
                    <b>Does not include</b>: New feature requests or
                    changes/additions to existing structures/workflows.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                  <h3>Rollout and Go-Live Support</h3>
                  <p className="mt-4">
                    We provide end-user support for UI interaction and access
                    during your new application and feature rollouts.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/go-live-support.jpg"
                    alt="Rollout and Go-Live Support"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/data-import.jpg"
                    alt="Legacy Data Import"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Legacy Data Import</h3>
                  <p className="mt-4">
                    Get support importing clean legacy data into your new
                    applications using the native Quickbase import
                    functionality.
                  </p>
                </div>*/}
                  </div>
                </div>
              </section>
            </LazyLoadComponent>
            <LazyLoadComponent>
              <section
                className="quick-base-glance"
                style={{ padding: "3rem 0" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="text-center mb-5">
                        Our 10-Point Security Audit
                      </h2>
                      <p className="text-center">Here’s what we cover:</p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <GrVulnerability />
                      <h4 className="my-4">1. Vulnerability Assessment</h4>
                      <p>
                        We identify security vulnerabilities based on data and
                        system access to prevent potential breaches.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <AiOutlineSecurityScan />
                      <h4 className="my-4">2. Data Protection Analysis</h4>
                      <p>
                        We ensure your sensitive data is stored properly,
                        protected, and compliant with the relevant data privacy
                        laws (HIPPA, SEC, PCI, etc.).
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <TbLockAccess />
                      <h4 className="my-4">3. Access Control Evaluation</h4>
                      <p>
                        We evaluate user access controls to prevent unauthorized
                        access to critical data from malicious partners or
                        employees trying to work around your systems.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <GrShieldSecurity />
                      <h4 className="my-4">4. Risk Assessment</h4>
                      <p>
                        We identify and prioritize potential security risks on a
                        client-by-client basis, providing a clear path to
                        mitigate risk.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <GrCompliance />
                      <h4 className="my-4">5. Compliance Verification</h4>
                      <p>
                        We check for compliance with relevant security standards
                        and regulations for clients in industries with
                        compulsory requirements.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <AiFillAlert />
                      <h4 className="my-4">6. Incident Response Readiness</h4>
                      <p>
                        We evaluate whether or not your organization can respond
                        effectively to security incidents, indicating the
                        strengths and weaknesses of your response plan.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <AiOutlineSafetyCertificate />
                      <h4 className="my-4">
                        7. Security Best Practices Implementation
                      </h4>
                      <p>
                        We inform your team of best practices for user
                        structuring along with data management, collection, and
                        protection in your Quickbase applications.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <MdOutlineLockClock />
                      <h4 className="my-4">
                        8. Regular
                        <br /> Security Updates
                      </h4>
                      <p>
                        We show your team how to review and adjust your systems
                        based on Quickbase’s latest security patches and
                        updates.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <BiCertification />
                      <h4 className="my-4">
                        9. Employee Training Recommendations
                      </h4>
                      <p>
                        We suggest training programs for staff to raise
                        awareness around security best practices, reducing
                        future risks to your organization.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <TbReportAnalytics />
                      <h4 className="my-4">10. Detailed Security Report</h4>
                      <p>
                        We provide a comprehensive report detailing security
                        findings and actionable recommendations for your team
                        with the option to implement changes with us.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-5">
                  <div className="hero-button text-center">
                    <Link
                      to="/contact/"
                      className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                      style={{ marginTop: "auto" }}
                    >
                      Book Your Security Consultation
                    </Link>
                  </div>
                </div>
              </section>
            </LazyLoadComponent>
            <LazyLoadComponent>
              <div
                className="text-center intro py-5 bg-gradient"
                style={{ padding: "5rem 0" }}
                id=""
              >
                {/* <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                        Reviews
                      </h5>
                      <h2 className="my-2 text-white mt-4">
                        What Our Clients Say
                      </h2>
                    </div>
                  </div>
                </div> */}
                <Testimonials2 />
              </div>
            </LazyLoadComponent>
            <LazyLoadComponent>
              <section
                className="bg-white quandary-connect-glance"
                style={{ padding: "3rem 0" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <h2>
                        Is the Quickbase Security Audit Right for Your Business?
                      </h2>
                      <p>You are ready for our security audit if:</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 mt-5 text-center">
                      <AiOutlineSafetyCertificate />
                      <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                        You Currently Use Quickbase
                      </h3>
                      <p>
                        While there are numerous types of security audits
                        available, we designed ours specifically for Quickbase
                        applications because we know the ins and outs of the
                        platform.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5 text-center">
                      <GrCompliance />
                      <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                        You Are Concerned About Regulatory Compliance
                      </h3>
                      <p>
                        We test applications to ensure they meet HIPPA, PCI,
                        SEC, and other compliance recommendations.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5 text-center">
                      <GoShieldLock />
                      <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                        You’re Concerned About Data Security
                      </h3>
                      <p>
                        You have numerous Quickbase applications, or it has been
                        a while, and you are concerned about who has access to
                        what types of data.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </LazyLoadComponent>
            <section
              className="bg-indigo"
              style={{
                paddingTop: "5rem",
                paddingBottom: "5rem",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 mx-auto text-center text-left-mobile">
                    <div>
                      <h2 className="text-white">
                        What Score Will Your Quickbase Applications Get?
                      </h2>
                      <p className="text-white">
                        Our 10-point security audit will show you how secure
                        your applications are. <br /> Protect your data. Protect
                        your investment. Protect your organization.
                      </p>

                      <Link
                        to="/contact/"
                        className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                        style={{ marginTop: "auto" }}
                      >
                        Book Your Security Consultation
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default QuickbaseSecurityAuditPage

export const QuickbaseSecurityAuditPagePageQuery = graphql`
  query QuickbaseSecurityAuditPagePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
